import { Box, Button, Container, Stack, Tooltip } from '@mui/material'
import React,{ useState, useContext } from 'react'
import RPLogo from './RPLogo'
import { ShoppingCart, SportsBar, VideoChatOutlined } from '@mui/icons-material'
import VideocamIcon from '@mui/icons-material/Videocam';
import { StorefrontContext, StorefrontProvider }  from "../../../context/StorefrontProvider";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

const MusicHeader = (props) => {
    const {checkoutModalOpen,
        setCheckoutModalOpen,
        listener,email, colors ,setUpdateModalOpen, albums,
        chatModalOpen, setChatModalOpen, tipModalOpen,setTipModalOpen,
        albumNav, setAlbumNav, isStreaming, setIsStreaming,setStreamModalOpen
    } = props
    const { musicCart} = useContext(StorefrontContext);
  return (
    <Stack sx={{
        position:'absolute', top:'0rem',
        left:'50%',
        width:'100%', height:'21vh',
        transform:'translate(-50%,0)',
        borderRadius:'5px 5px 0px 0px',
        // maxWidth:'150px',
        // border:'1px solid red',
        margin:'auto',
        // marginBottom:'1.5rem',
        //padding:'0.25rem',
        justifyContent:'center', alignContent:'center',alignItems:'center',
        boxShadow:'inset 0px -2px 11px 5px rgba(255,255,255,0.5),0px -5px 5px 2px rgba(0,0,0,0.1)',
        backgroundImage:`url("https://res.cloudinary.com/dzxzdsnha/image/upload/v1690040515/Rex_Entropy_Hero_Star_Bg_SML_btvj6p.png")`,//backgroundAttachment:'fixed',
        backgroundRepeat: 'no-repeat',
        // backgroundPositionY:`${album?.album_pic[2]}px`,
        backgroundSize: 'cover',
        backgroundPosition:`fixed`,
    }}> 
    {/* <Box sx={{
            border:'2px solid yellow',
        display:'flex',
        flexDirection:'column',
        // position:'absolute',
        // top:'0px',
        // left:'0px',
        justifyContent:'center',
        height:'30vh',
        // width:'100vw', 
        border:'1px solid blue', 
        margin:'auto',
        height:'fit-content'
        }}> */}
         <Stack sx={{border:`1px solid ${colors.main}`, 
                    position:'absolute',
                    margin:'auto',
                    maxWidth:'150px',
                    top:'-1.5rem', zIndex:999,
                    backgroundColor:'#FFF',
                    width:'fit-content', margin:'auto',
                    color: colors.accent,
                    cursor:'pointer',
                    boxShadow:'0 0 3px 2px rgba( 0, 0, 0, 0.1), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
                    '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                    '&:active': {boxShadow:'inset 0 0 3px 2px rgba( 0, 0, 0, 0.1)'},
                    borderRadius:'5px'}}
                    onClick={()=>{
                      setUpdateModalOpen(true)
                    }}
                    >
                      <span style={{fontSize:'0.8rem',borderBottom:`1px solid ${colors.main}`,
                      borderRadius:'5px 5px 0 0',
                        padding:' 0.125rem 0.25rem',}}>
                        Welcome, <b>{listener?.preferredName? listener?.preferredName: email}!</b>
                      </span>
                      <b style={{fontSize:'0.75rem',backgroundColor: colors.accent,
                        borderRadius:'0 0 5px 5px',color:'#FFF',padding:'0.25rem'}}>
                        click to change name
                      </b>
                </Stack>
   <Stack 
    sx={{height:'15vh',
        position:'absolute',top:'0rem',
        // border:'1px solid rgba( 0 , 255, 1,0.5)',
        width:'250px',
        // left:'50%'
        }}>
        <RPLogo />
    </Stack>


 {albums && <Box sx={{
//     position:'absolute',
//  top:'12vh',
//  left:'25%',
 display:'flex', 
 width: 'fit-content',
 alignSelf:'center',
//  border:'1px solid red',
//  transform:'translate(5%,0)',
zIndex:999,
    margin:'auto',marginTop:'16vh', gap:'0.5rem',}}>
    {albums?.map((album,i)=>{

        return (
        <Tooltip title={album?.title}  >
            <Box sx={{position:'relative', width:'52px',height:'52px', 
                borderRadius:'5px',
                border: album.isNew?'2px solid #00FF00':'',
                background:'#000',
                }} >
                
                <Box 
                key={i}
                component='img'
                src={album?.album_pic[0]}
                sx={{width:'50px', height:'50px', borderRadius:'5px',cursor:'pointer',
                    border:`1px solid ${albumNav === i ? '#757575':'#000'}`,cursor:'pointer',
                boxShadow: albumNav === i ?'0px 0px 7px 2px rgba(0,0,0,0.2)':''
                }}
                onClick={()=>{  
                    setAlbumNav(i)
                }}
                >
                </Box>
                    {album?.isNew &&  <Box 
                        sx={{position:'absolute',borderRadius:'5px',padding:'0rem 0.2rem',
                        fontSize:'0.75rem', top:'-10px',border:'2px solid #00FF00',
                        color:'#00FF00',
                        left:'50%', transform:'translate(-50%,0)',background:'#000',width:'fit-content',height:'1rem'}}><b className="blink">new!</b></Box>}
            </Box>
        </Tooltip>
        )
    })}

    </Box>}
    <Stack sx={{position:'absolute', 
            right: '0.5rem',top:'0.5rem',
            justifyContent:'end',
            gap:'0.5rem',

            // alignItems:'end'
            }}>
            <Tooltip title='live stream'>

                <Box sx={{display:'flex',
                    gap:'0.25rem', 
                    borderRadius:'10px',alignContent:'center', alignItems:'center',
                    padding:'0.125rem 0.5rem', cursor:'pointer',
                    boxShadow:`0px 0px 5px 2px ${isStreaming?'rgba( 0 , 255, 1,0.5)': 'rgba(255 , 1, 0,0.5)'}, inset 0px 0px 3px 1px ${isStreaming?'rgba( 0 , 255, 1,0.5)': 'rgba(255 , 1, 0,0.5)'}`,
                    border:`1px solid ${isStreaming?'rgba( 0 , 255, 1,0.5)': 'rgba(255 , 1, 0,0.5)'}`, 
                    '&:hover': {background:'rgba( 255, 255, 255,0.5)'},
                    '&:active': {boxShadow:'inset 0 0 3px 2px rgba( 0, 0, 0, 0.1)'}
                }}
                    onClick={()=>{setStreamModalOpen(true)}}
                    >
                    <div 
                    className={!isStreaming?'blink':'blink-2'}
                    style={{width:'0.5rem', height:'0.5rem',
                    
                            borderRadius:'100%',backgroundColor: isStreaming?'rgba( 0 , 255, 1,0.5)': 'rgba(255 , 1, 0,0.5)'}}>&nbsp;</div>
                    <Stack style={{color: isStreaming?'rgba( 0 , 255, 1,0.5)': 'rgba(255 , 1, 0,0.5)',fontSize:'0.8rem'}}> 
                        {/* <b>
                        Live
                        </b> */}
                        <b>
                        Stream{isStreaming?'ing':''}
                        </b>
                </Stack>
                </Box>
            </Tooltip>

        <Tooltip title='🔥🙏🙌 send some love 🙌🙏🔥'>

            <Button 
                className='blink'
                sx={{
                    display:'flex',
                    fontSize:'0.65rem', 
                    cursor:'pointer',
                    // position:'absolute',
                    // top:'2.5rem',
                    // right:'0.5rem',
                    color:'rgba(255,102,1,1)', 
                    borderRadius:'50px 50px 50px 50px', 
                    // width: '1.5rem',
                     height:' 1.5rem',
                    boxShadow:'0px 0px 5px 2px #00FF00',
                    border: '2px solid #00FF00',
                    alignItems: 'center',
                    alignContent:'center',
                    justifyContent:'center',
                // backgroundColor:'rgba(0,255,0,0.5)',
                gap:'0.5rem',
                }}
                onClick={()=>{
                    setTipModalOpen(true)
                }}
                >Buy a<SportsBar
                 sx={{cursor:'pointer' ,fontSize:'1.2rem',}}
                />
                {/* drink */}
            </Button>
        </Tooltip>

        <Tooltip title='video chat'>

            <Button
                // className='blink'
                 size='small'
                sx={{
                    cursor:'pointer',
                    // position:'absolute',
                    // fontSize:'0.65rem',
                    // top:'4.5rem',
                    // right:'0.5rem',
                    color:'#00FF00', 
                    height:'1.5rem',
                     padding:'0.5rem',

                    backgroundColor:'rgb(255,102,1,0.5)',
                    // backgroundColor:'#FFF',
                    borderRadius:'50px 50px 50px 50px',
                    // width: '2rem', height:' 2rem',
                    boxShadow:'0px 0px 5px 2px rgb(255,102,1)',
                    border: '2px solid rgb(255,102,1)',
                    alignItems: 'center',
                    alignContent:'center',
                    justifyContent:'center',
                    gap:'0.25rem'
                }}
                onClick={()=>{ setChatModalOpen(true)}}
                >

                <VideocamIcon sx={{cursor:'pointer' ,fontSize:'1rem',}}/>
                chat
            </Button>
        </Tooltip>
    </Stack>
    <Tooltip title='cart'>
    <Box sx={{display:'flex',position:'absolute', bottom:'1.5rem', right:'0.5rem',
        color:'#FFF', cursor:'pointer', minWidth:'2.5rem', height:'1rem',
        alignContent:'center', alignItems:'center', textAlign:'center',justifyContent:'center',
        border:'2px solid #FFF', borderRadius:'50px', padding:'0.25rem', gap:'0.5rem'}}
        onClick={()=>{setCheckoutModalOpen(!checkoutModalOpen)}}
        >
       {musicCart?.items?.length ?<b>{musicCart?.items?.length}</b> : <b>0</b> } 
       <ShoppingCart sx={{alignSelf:'center', fontSize:'0.95rem' }} className='button-woddle blink'/>
    </Box>
    </Tooltip>
    <Box sx={{
        //  border:'1px solid rgba(255 , 1, 0,0.5)',
        position:'absolute',
        bottom:0,
        left: 0,
        width:'100%',
        height:'2rem',
        backgroundImage:`linear-gradient(to top, #ffffff, #ffffff, #ffffff80, #ffffff40, #ffffff00);
  );`}}>
     </Box>
     </Stack>
  )
}

export default MusicHeader