import ContactPhone from '@mui/icons-material/ContactPhone'
import { Container, Modal,Box, Button, Input, Typography, TextField, Stack } from '@mui/material'
import React, { useState, useContext,useEffect } from 'react'
import CloseButton from '../../../components/CloseButton'
import TrackDownload from './TrackDownload'
import AddToCartButton from '../../../components/AddToCartButton'
import { StorefrontContext, StorefrontProvider }  from "../../../context/StorefrontProvider";



const PinModal = (props) => {
    const { setPinModalOpen, pinModalOpen, item ,album} = props
    const [ pin, setPin ] = useState()
    const [ isLocked, setIsLocked ] = useState(true)
    const [ actionError, setActionError ] = useState('')
    const [ viaText, setViaText ] = useState('')
    const  [ showEnterPin, setShowEnterPin ] = useState(false)

     const { musicCart, setMusicCart,
        modalOpen, setModalOpen, defaultQR, currentProduct,
          setCurrentProduct,buildStep, setBuildStep,orderDefault,
          cart,setCart,currentOrder, setCurrentOrder,shop, selectedSize,
          storefrontData, updateStorefrontData,
          framingOpen,setFramingOpen,createCart, generateOrder,createOrder,
          } = useContext(StorefrontContext);


    const handlePinCheck = () =>{
        console.log('pin:',pin)
        console.log('item pin:',item?.pin)
        if( !pin ){
            setActionError('Please Enter Pin')
        }
        if( pin?.toString() === item?.pin?.toString() ){
            // setPinModalOpen(false)
            setActionError('')
            setIsLocked(false)
        }else{
            setActionError('Incorrect Pin')
        }
    }
    const handleCartAdd = async() =>{
        const isOnBoughtAlbum = musicCart?.items?.find((cartItem)=>{
            console.log('cartItem: ',cartItem)
                if(cartItem?.tracks?.length > 0){
                    return cartItem?.tracks?.find((track)=>{ 
                            return track?._id === item?._id
                        }
                    )
                }else return null;
            })
        // console.log('isOnBoughtAlbum',isOnBoughtAlbum)
        if(!musicCart?.items?.includes(item) && !isOnBoughtAlbum ){
              if( !musicCart?._id ){
                // await setCurrentProduct(item)
               
               const resp = await createCart(item,'music')
                if(resp?._id){
                    setMusicCart(resp)
                    setPinModalOpen(false)
                }else{
                    setActionError('Error adding to cart')
                }
                }else if(musicCart?._id){
                    try{
                        if(musicCart?.items?.length > 0){
                            let temp = musicCart?.items
                            temp?.push(item)
                            setMusicCart({
                                ...musicCart,
                                items:temp,
                                tracks:temp,
                                
                            })
                            updateStorefrontData({
                                ...storefrontData, 
                                musicCart
                        })
                        }else{
                            setMusicCart({
                                ...musicCart,
                                items:[currentProduct],
                                tracks:[currentProduct]
                            })
                            updateStorefrontData({
                                ...storefrontData, 
                                musicCart
                        })
                        }
                        console.log('[AddToCart]:',musicCart)
                        setPinModalOpen(false)
                    }catch(err){console.log(err)}
                }else{setActionError('Error saving to cart!')}
        }else{
            setActionError('Item already in cart!')
            if(isOnBoughtAlbum){
                setViaText(`via ${isOnBoughtAlbum?.title}`)
            }
            return
        }
    }
    useEffect(()=>{
        setActionError('')
        setViaText('')
    },[])
    useEffect(()=>{
        // setActionError('')
        setViaText('')
    },[currentProduct])
  return (
    <>
        <Modal 
            open={pinModalOpen}
            onClose={()=>setPinModalOpen(!pinModalOpen)}
            >
            <Container sx={{zIndex:999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '350px',
                    minHeight:'250px',
                    maxHeight:'80%',
                    backgroundColor:'#FFF',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    // gap:'0.5rem',
                    padding:'0.5rem',
                    paddingTop:'1rem'
                    
                    }}>
                        <Box sx={{position:'absolute', top:'-0.5rem', right:'-0.5rem'}}>
                            <CloseButton func={setPinModalOpen} val={pinModalOpen}
                        
                            />
                        </Box>

                       {isLocked && !showEnterPin &&<Button variant='contained' 
                        sx={{border:'1px solid #868686',
                            position:  showEnterPin?'absolute': 'relative',
                            left:  showEnterPin? '0.75rem': '',
                            top:  showEnterPin? '0.75rem': '' }}
                            onClick={()=>{setShowEnterPin(!showEnterPin)}}
                            >
                               Enter Promo Code
                            </Button>}
                        {/* <Stack sx={{border:'1px solid #868686',
                            borderRadius:'10px', padding:'0.5rem'
                        }}> */}
                            {isLocked && showEnterPin && 
                            <Stack sx={{
                                position:'relative',
                                justifyContent:'center',
                                    textAlign:'center',
                                    alignItems:'center',
                                    gap:'0.5rem',
                                    border:'1px solid #868686',
                                    borderRadius:'10px', padding:'0.5rem',
                                    paddingTop:'2rem',
                                    boxShadow:'0px 0px 3px 1px rgba(0,0,0,0.3)'

                                    }}>
                                        <Box sx={{
                                            // border:'1px solid #868686',
                                            cursor:'pointer',

                                            position:  'absolute',
                                            left: '0.5rem',
                                            top:  '0.55rem',
                                            // borderRadius: showEnterPin? '5px 10px 0 10px':'5px', padding:'0.25rem', cursor:'pointer',
                                            // borderBottom: showEnterPin? '0px' :'1px solid #868686'
                                            }}
                                            onClick={()=>{setShowEnterPin(!showEnterPin)}}
                                        >
                                                Close
                                            </Box>
                                <h3 style={{fontWeight:'bold'}}>
                                Please Enter Provided Pin To Download!
                                </h3> 
                                <Input placeholder='pin' sx={{
                                        display:'flex',
                                        maxLength:6,
                                        width:'50%',
                                        textAlign:'center'
                                    }} onChange={(e)=>{
                                        setPin(e.target.value)
                                }}
                                />

                            <Button variant='outlined' onClick={handlePinCheck}>
                                submit
                            </Button>
                            <Typography sx={{color:'red'}}>
                                {actionError}
                            </Typography>
                                </Stack>
                                }
                        {/* </Stack> */}

                        {!isLocked &&
                        <TrackDownload album={album} track={item}/>
                        }

                 <Typography sx={{marginTop:'0.75rem'}}>
                        - or -
                 </Typography>
                 <Box sx={{marginTop:'0.75rem'}}>
                        <Button variant='contained' 
                            // disabled={!item?.isActive}
                            disabled={true}
                            onClick={()=>{
                                
                                handleCartAdd()

                            }}>
                            Add To Cart
                        </Button>
                    </Box>
                    <Stack sx={{marginTop:'0.5rem', textAlign:'center'}}>
                        <b > 
                            {actionError}
                        </b>
                        <span>
                            {viaText}
                        </span>
                    </Stack>
                    </Box>
                    
            </Container>
        </Modal>
        
    </>
  )
}

export default PinModal